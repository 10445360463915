// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expenses-list {
    list-style: none;
    padding: 0;
}

.expenses-list__fallback {
    color: white;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Expenses/ExpensesList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".expenses-list {\r\n    list-style: none;\r\n    padding: 0;\r\n}\r\n\r\n.expenses-list__fallback {\r\n    color: white;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
