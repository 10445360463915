// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expense-date {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 5.5rem;
  border: 1px solid #ececec;
  background-color: #2a2a2a;
  color: white;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.expense-date__month {
  font-size: 0.75rem;
  font-weight: bold;
}

.expense-date__year {
  font-size: 0.75rem;
}

.expense-date__day {
  font-size: 1.5rem;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Expenses/ExpenseDate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".expense-date {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 5.5rem;\r\n  height: 5.5rem;\r\n  border: 1px solid #ececec;\r\n  background-color: #2a2a2a;\r\n  color: white;\r\n  border-radius: 12px;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.expense-date__month {\r\n  font-size: 0.75rem;\r\n  font-weight: bold;\r\n}\r\n\r\n.expense-date__year {\r\n  font-size: 0.75rem;\r\n}\r\n\r\n.expense-date__day {\r\n  font-size: 1.5rem;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
