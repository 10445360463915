// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-expense {
    background-color: #a892ee;
    padding: 1rem;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.new-expense button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid #40005d;
    background-color: #40005d;
    color: white;
    border-radius: 12px;
    margin-right: 1rem;
}

.new-expense button:hover,
.new-expense button:active {
    background-color: #510674;
    border-color: #510674;
}

.new-expense button.alternative {
    color: #220131;
    border-color: transparent;
    background-color: transparent;
}

.new-expense button.alternative:hover,
.new-expense button.alternative:active {
    background-color: #ddb3f8;
}`, "",{"version":3,"sources":["webpack://./src/components/NewExpense/NewExpense.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;;IAEI,yBAAyB;AAC7B","sourcesContent":[".new-expense {\r\n    background-color: #a892ee;\r\n    padding: 1rem;\r\n    margin: 2rem auto;\r\n    width: 50rem;\r\n    max-width: 95%;\r\n    border-radius: 12px;\r\n    text-align: center;\r\n    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);\r\n}\r\n\r\n.new-expense button {\r\n    font: inherit;\r\n    cursor: pointer;\r\n    padding: 1rem 2rem;\r\n    border: 1px solid #40005d;\r\n    background-color: #40005d;\r\n    color: white;\r\n    border-radius: 12px;\r\n    margin-right: 1rem;\r\n}\r\n\r\n.new-expense button:hover,\r\n.new-expense button:active {\r\n    background-color: #510674;\r\n    border-color: #510674;\r\n}\r\n\r\n.new-expense button.alternative {\r\n    color: #220131;\r\n    border-color: transparent;\r\n    background-color: transparent;\r\n}\r\n\r\n.new-expense button.alternative:hover,\r\n.new-expense button.alternative:active {\r\n    background-color: #ddb3f8;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
