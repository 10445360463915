import ExpenseForm from "./ExpenseForm";
import "./NewExpense.css";
import {useState} from "react";
const NewExpense = (props) => {
    const [isEditing, setIsEditing] = useState(true);

    const SaveExpenseDataHandler = (enteredExpenseData) => {
        const expenseData = {
            ...enteredExpenseData,
            id: Math.random().toString()
        };
        props.onAddExpense(expenseData);
    };

    const startEditingHandler = () => {
        setIsEditing(true);
    }

    const stopEditingHandler = () => {
        setIsEditing(false);
    }

    return (
        <div className="new-expense">
            {!isEditing && (<button onClick={startEditingHandler}>Add New Expense</button>)}
            {isEditing && (
                <ExpenseForm onSaveExpenseData={SaveExpenseDataHandler}
                    onCancel={stopEditingHandler}/>
            )}
        </div>
    );
}

export default NewExpense;