import ExpenseItem from './ExpenseItem';
import Card from '../UI/Card';
import './Expenses.css';
import ExpensesFilter from "./ExpensesFilter";
import {useState} from "react";
import ExpensesList from "./ExpensesList";
import ExpensesChart from "./ExpensesChart";

function Expenses(props) {

    const [filteredYear, setFilteredYear] = useState('2020');
    const [filterInfoText, setFilterInfoText] = useState('2019, 2021 & 2022');
    const filterChangeHandler = (selectedYear) => {
        setFilteredYear(selectedYear);

        if(selectedYear === '2019') {
            setFilterInfoText('2020, 2021 & 2022');
        } else if(selectedYear === '2020') {
            setFilterInfoText('2019, 2021 & 2022');
        } else if(selectedYear === '2021') {
            setFilterInfoText('2019, 2020 & 2022');
        } else if(selectedYear === '2022') {
            setFilterInfoText('2019, 2020 & 2021');
        }
    }

    const filteredExpenses = props.items.filter(expense =>  {
        return expense.date.getFullYear().toString() === filteredYear;
    });

    return (
        <div>
            <Card className="expenses">
              <ExpensesFilter selected={filteredYear} onChangeFilter={filterChangeHandler}/>
                <p>Data for years {filterInfoText} is hidden</p>
                <ExpensesChart expenses={filteredExpenses} />
                <ExpensesList items={filteredExpenses}/>
            </Card>
        </div>
    );
}

export default Expenses;
