// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-expense__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.new-expense__control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
}

.new-expense__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 20rem;
    max-width: 100%;
}

.new-expense__actions {
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/NewExpense/ExpenseForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".new-expense__controls {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 1rem;\r\n    margin-bottom: 1rem;\r\n    text-align: left;\r\n}\r\n\r\n.new-expense__control label {\r\n    font-weight: bold;\r\n    margin-bottom: 0.5rem;\r\n    display: block;\r\n}\r\n\r\n.new-expense__control input {\r\n    font: inherit;\r\n    padding: 0.5rem;\r\n    border-radius: 6px;\r\n    border: 1px solid #ccc;\r\n    width: 20rem;\r\n    max-width: 100%;\r\n}\r\n\r\n.new-expense__actions {\r\n    text-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
