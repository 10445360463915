// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #4b4b4b;
}

.expense-item__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  justify-content: flex-start;
  flex: 1 1;
}

.expense-item h2 {
  color: #3a3a3a;
  font-size: 1rem;
  flex: 1 1;
  margin: 0 1rem;
  color: white;
}

.expense-item__price {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #40005d;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
}

@media (min-width: 580px) {
  .expense-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1;
  }

  .expense-item__description h2 {
    font-size: 1.25rem;
  }

  .expense-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Expenses/ExpenseItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,qBAAqB;EACrB,yBAAyB;EACzB,2BAA2B;EAC3B,SAAO;AACT;;AAEA;EACE,cAAc;EACd,eAAe;EACf,SAAO;EACP,cAAc;EACd,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,SAAO;EACT;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;EACxB;AACF","sourcesContent":[".expense-item {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 0.5rem;\r\n  margin: 1rem 0;\r\n  background-color: #4b4b4b;\r\n}\r\n\r\n.expense-item__description {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n  align-items: flex-end;\r\n  flex-flow: column-reverse;\r\n  justify-content: flex-start;\r\n  flex: 1;\r\n}\r\n\r\n.expense-item h2 {\r\n  color: #3a3a3a;\r\n  font-size: 1rem;\r\n  flex: 1;\r\n  margin: 0 1rem;\r\n  color: white;\r\n}\r\n\r\n.expense-item__price {\r\n  font-size: 1rem;\r\n  font-weight: bold;\r\n  color: white;\r\n  background-color: #40005d;\r\n  border: 1px solid white;\r\n  padding: 0.5rem;\r\n  border-radius: 12px;\r\n}\r\n\r\n@media (min-width: 580px) {\r\n  .expense-item__description {\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    flex: 1;\r\n  }\r\n\r\n  .expense-item__description h2 {\r\n    font-size: 1.25rem;\r\n  }\r\n\r\n  .expense-item__price {\r\n    font-size: 1.25rem;\r\n    padding: 0.5rem 1.5rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
